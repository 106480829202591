<template>
    <div>
        <BT-Fast-Start-Banner />
        <BT-Blade-Item
            :bladesData="bladesData"
            bladeName="measurement"
            navigation="measurements"
            title="Measurement">
            <template slot-scope="{ item, data }">

                <BT-Field-String
                    v-model="item.measurementName"
                    label="Name"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-String
                    v-model="item.abbreviation"
                    label="Abbreviation"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-Number
                    v-model.number="item.height"
                    label="Height (mm)"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-Number
                    v-model.number="item.width"
                    label="Width (mm)"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-Number
                    v-model.number="item.length"
                    label="Length (mm)"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-Number
                    v-model.number="item.weight"
                    label="Weight (kg)"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-Number
                    v-model.number="item.volume"
                    label="Volume (ltr)"
                    :isEditing="data.isEditing || data.isNew" />

                <v-list-item>
                    <v-list-item-content>
                        <BT-Select
                            v-model="item.measurementStandard"
                            itemText="standard"
                            itemValue="standard"
                            label="Measurement Standard"
                            navigation="measurement-standards" />
                        <!-- <BT-Measurement-Standard v-model="item.measurementStandard" /> -->
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <BT-Tags
                            v-model="item.tags"
                            label="Tags"
                            :options="['Production','Dispatch']"
                            key="0.6" />
                    </v-list-item-content>
                </v-list-item>
                        
                <v-expansion-panels v-if="!data.isNew">
                    <BT-Expansion-Panel-List
                        label="Product Units Per Package">
                        <template v-slot:items>
                            <BT-Increments :measurementID="item.id" :isEditing="$canEdit('stock-increments')" />
                        </template>
                    </BT-Expansion-Panel-List>
                </v-expansion-panels>

            </template>
        </BT-Blade-Item>
    </div>
</template>

<script>
export default {
    name: 'Measurement-Blade',
    components: {
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTIncrements: () => import('~components/BT-Increments.vue'),
    },
    props: {
        bladesData: null
    }
}
</script>